import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import Header from "../components/header"
import BackgroundWrapper from "../components/wrapper-background"
import ContentWrapper from "../components/wrapper-content"
import theme from "../theme"

const Content = styled(ContentWrapper)`
  color: ${theme.color.white}; 
`

const Privacy = () => (
  <Layout>
    <SEO title="Privacy" />

    <Header />

    <BackgroundWrapper className="backgroundBlue">

      <Content>
 
        <h1>Privacy</h1>
        <p>Coming soon</p>
     
      </Content>
      
    </BackgroundWrapper>

  </Layout>
)

export default Privacy
